<template>
<!-- <div class="container"> -->
  <!-- <div class="alert alert-warning alert-dismissible fade show" role="alert">
  {{ this.text }}
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div> -->

    <v-snackbar :timeout="timeout" v-model="alert" :color="color" multi-line top>
{{ text }}
  <template v-slot:action="{ attrs }">
<v-btn dark text @click="close" v-bind="attrs">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
      </template>
</v-snackbar>

<!-- </div> -->


</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Alert',
     data() {
      return {
       timeout: 2000
      }
    },
    computed: {
      ...mapGetters({
        status : 'alert/status',
        color  : 'alert/color',
        text   : 'alert/text'
      }),
      alert: {
        get () {
          return this.status
        },
        set (value) {
          this.setAlert({
            status : value,
            type  : 'success',
            text  : 'test',
          })
        }
      },
      statusalert: function(){
        return this.status
      },
      
      
    },


    methods: {
      ...mapActions({
        setAlert  : 'alert/set',
      }),
      close(){
        this.setAlert({
          status : false
        })
      }, 
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
           if(this.dismissCountDown == 0){
              this.setAlert({
                status : false
              })
        }
      },
      
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }  
    }
}
</script>

<style scoped>

</style>